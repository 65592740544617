import React from 'react';
import PropTypes from 'prop-types';
import { Animal as ApiAnimalSlug } from 'api/helpers/search/constants';
import AnimalIcon from 'components/AnimalIcon';
import { UlStyled, LiStyled, SpanStyled } from './Animals.style';

const Animals = ({ animals, animalCount = 2, className, ...props }) => {
    // animals that should not display a count
    const animalsSlugsWithoutCount = [
        ApiAnimalSlug.POULTRY,
        ApiAnimalSlug.FARMANIMAL,
        ApiAnimalSlug.FISH,
    ];
    // shows count if slug is not one of animalsSlugsWithoutCount
    const showCount = (slugToCheck) =>
        !animalsSlugsWithoutCount.some((slug) => slug === slugToCheck);

    let animalsList = animals.slice(0, animalCount).map((animal) => (
        <LiStyled key={`${animal.slug}`}>
            {showCount(animal.slug) && animal.count ? (
                <SpanStyled data-testid="Animal__count">{animal.count}</SpanStyled>
            ) : null}
            <AnimalIcon animal={animal.slug} size="large" palette="text" />
        </LiStyled>
    ));

    if (animals.length > animalCount) {
        animalsList = animalsList.concat(
            <LiStyled key="animals-more">
                <SpanStyled data-testid="plus-sign">+</SpanStyled>
            </LiStyled>
        );
    }

    return (
        <UlStyled data-testid="animals-list" className={className} {...props}>
            {animalsList}
        </UlStyled>
    );
};

Animals.propTypes = {
    animals: PropTypes.arrayOf(
        PropTypes.shape({
            slug: PropTypes.oneOf(Object.values(ApiAnimalSlug)),
            count: PropTypes.number,
        })
    ).isRequired,
    animalCount: PropTypes.number.isRequired,
    className: PropTypes.string,
};

Animals.defaultProps = {
    className: '',
};

export default Animals;

import styled from 'styled-components';
import { Span } from 'typography';

const UlStyled = styled.ul`
    display: flex;
    padding: 0;
    margin: 0;
`;

const LiStyled = styled.li`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: ${({ theme }) => theme.spacing[2]};

    &:last-of-type {
        margin-right: 0;
    }
`;

const SpanStyled = styled(Span).attrs({
    styleOf: 'smallText',
})`
    padding-right: ${({ theme }) => theme.spacing['0.5']};
    color: ${({ theme }) => theme.palette.textLight};
`;

export { UlStyled, LiStyled, SpanStyled };
